<template>
  <div class="pt-6 pb-0 px-1">
    <MatrizActasCabeceras :matriz="matrizActaCabecera"></MatrizActasCabeceras>
    <v-row class="mt-5 mb-0">
      <v-col cols="6" sm="2" md="2" offset-sm="1" class="d-flex justify-end">
        <v-switch dense class="mt-0" v-model="opcionSolo" label="Solo yo" color="primary" hide-details></v-switch>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <v-menu ref="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              v-model="dateRangeText"
              label="Filtro por fechas"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dateRange" range locale="es-es" :first-day-of-week="1"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <v-select
          dense
          multiple
          @blur="recargarListYAreas"
          v-model="status"
          :items="itemsStatusResponsible"
          no-data-text="No se encontró información"
          item-text="text"
          item-value="value"
          label="Estado"
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="selectAllStatus" @mousedown.prevent>
              <v-list-item-action>
                <v-icon color="primary">{{ iconCheckboxAll }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> TODOS </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <template v-if="status.length == itemsStatusResponsible.length">
              <span v-if="index === 0">TODOS</span>
            </template>
            <template v-else>
              <span v-if="index > 0">,</span><span>{{ item.text }}</span>
            </template>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <v-select
          dense
          multiple
          @blur="initialize"
          v-model="areas"
          :items="itemsAreas"
          :return-object="false"
          no-data-text="No se encontró información"
          item-text="text"
          item-value="value"
          label="Área"
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="selectAllAreas" @mousedown.prevent>
              <v-list-item-action>
                <v-icon color="primary">{{ iconCheckboxAllAreas }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> TODOS </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <template v-if="areas.length == itemsAreas.length">
              <span v-if="index === 0">TODOS</span>
            </template>
            <template v-else>
              <span v-if="index > 0">,</span><span>{{ item.value }}</span>
            </template>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="actas"
      :height="480"
      class="border"
      multi-sort
      :mobile-breakpoint="0"
      :items-per-page="-1"
      no-results-text="No se encontraron datos"
      no-data-text="No se encontraron datos"
      :search="search"
      item-class="class"
      :fixed-header="true"
    >
      <template v-slot:top>
        <div class="w-100 d-flex justify-space-between align-center pa-2">
          <div class="black--text">COMPROMISOS/ACUERDOS</div>
          <v-divider class="mx-4" vertical></v-divider>
          <v-text-field
            v-model="search"
            dense
            append-icon="mdi-magnify"
            label="Buscar"
            class="mt-0 text-field-search-matriz"
            background-color="rgb(220, 220, 220, 0.16)"
            rounded
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" vertical></v-divider>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mr-2" color="primary" fab outlined small @click="initialize" v-bind="attrs" v-on="on">
                <v-icon>mdi-table-refresh</v-icon>
              </v-btn>
            </template>
            <span>Actualizar</span>
          </v-tooltip>
          <v-tooltip v-auth-acl="'gh-act-mat_aac-export'" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" fab dark small @click="exportarbase" v-bind="attrs" v-on="on">
                <v-icon dark>mdi-cloud-download</v-icon>
              </v-btn>
            </template>
            <span>Exportar BD</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.areainvolucrada`]="{ item }">
        <div style="display: flex;align-items: center;">
          <ul class="common-list">
            <li :key="i" v-for="(e, i) in item.responsable">
              {{ e.subarea ? e.subarea : 'OTROS' }}
            </li>
          </ul>
        </div>
      </template>
      <template v-slot:[`item.responsableinvolucrado`]="{ item }">
        <div style="display: flex;align-items: center;">
          <ul class="common-list">
            <li :key="i" v-for="(e, i) in item.responsable">
              {{ e.responsable }}
            </li>
          </ul>
        </div>
      </template>
      <template v-slot:[`item.arearesponsable`]="{ item }">
        <div style="display: flex;align-items: center;">
          <ul class="common-list">
            <li :key="i" v-for="(e, i) in responsableAcuerdo(item.responsable)">
              {{ e.subarea ? e.subarea : 'OTROS' }}
            </li>
          </ul>
        </div>
      </template>
      <template v-slot:[`item.responsable`]="{ item }">
        <div style="display: flex;align-items: center;">
          <ul class="common-list">
            <li :key="i" v-for="(e, i) in responsableAcuerdo(item.responsable)">
              {{ e.responsable }}
            </li>
          </ul>
        </div>
      </template>
      <template v-slot:[`item.actanumero`]="{ item }">
        <div v-auth-acl:conditional="'gh-act-mat_aac-view'">
          <v-chip v-auth-acl-if color="blue" dark @click="viewActas(item)">{{ item.actanumero }}</v-chip>
          <v-chip v-auth-acl-else color="blue" dark>{{ item.actanumero }}</v-chip>
        </div>
      </template>
      <template v-slot:[`item.fechareprogramacion1`]="{ item }">
        <div v-auth-acl:conditional="'gh-act-mat_aac-reprogramming'">
          <div v-auth-acl-if>
            <span v-if="item.disabledFechareprogramacion1 || parseInt(item.subio) === 1">
              {{ item.fechareprogramacion1 }}
            </span>
            <v-edit-dialog
              v-else
              :return-value.sync="item.fechareprogramacion1"
              @save="changeFechaReprogramacion1(item)"
            >
              {{ item.fechareprogramacion1 }}
              <template v-slot:input>
                <v-date-picker
                  ref="pickerFechareprogramacion1"
                  v-model="item.fechareprogramacion1"
                  locale="es-es"
                  :first-day-of-week="1"
                  landscape
                ></v-date-picker>
              </template>
            </v-edit-dialog>
          </div>
          <div v-auth-acl-else>
            <span>
              {{ item.fechareprogramacion1 }}
            </span>
          </div>
        </div>
      </template>
      <template v-slot:[`item.fechareprogramacion2`]="{ item }">
        <div v-auth-acl:conditional="'gh-act-mat_aac-reprogramming'">
          <div v-auth-acl-if>
            <span v-if="item.disabledFechareprogramacion2 || parseInt(item.subio) === 1">
              {{ item.fechareprogramacion2 }}
            </span>
            <v-edit-dialog
              v-else
              :return-value.sync="item.fechareprogramacion2"
              @save="changeFechaReprogramacion2(item)"
            >
              {{ item.fechareprogramacion2 }}
              <template v-slot:input>
                <v-date-picker
                  ref="pickerFechareprogramacion2"
                  v-model="item.fechareprogramacion2"
                  locale="es-es"
                  :first-day-of-week="1"
                  landscape
                ></v-date-picker>
              </template>
            </v-edit-dialog>
          </div>
          <div v-auth-acl-else>
            <span>
              {{ item.fechareprogramacion2 }}
            </span>
          </div>
        </div>
      </template>
      <template v-slot:[`item.fechareprogramacion3`]="{ item }">
        <div v-auth-acl:conditional="'gh-act-mat_aac-reprogramming'">
          <div v-auth-acl-if>
            <span v-if="item.disabledFechareprogramacion3 || parseInt(item.subio) === 1">
              {{ item.fechareprogramacion3 }}
            </span>
            <v-edit-dialog
              v-else
              :return-value.sync="item.fechareprogramacion3"
              @save="changeFechaReprogramacion3(item)"
            >
              {{ item.fechareprogramacion3 }}
              <template v-slot:input>
                <v-date-picker
                  ref="pickerFechareprogramacion3"
                  v-model="item.fechareprogramacion3"
                  locale="es-es"
                  :first-day-of-week="1"
                  landscape
                ></v-date-picker>
              </template>
            </v-edit-dialog>
          </div>
          <div v-auth-acl-else>
            <span>
              {{ item.fechareprogramacion3 }}
            </span>
          </div>
        </div>
      </template>
      <template v-slot:[`item.estado`]="{ item }">
        <v-chip v-if="parseInt(item.subio) === 1 || item.estado.value === 'NR'" :color="item.estado.color" dark>{{
          item.estado.text
        }}</v-chip>
        <v-chip v-else :color="item.estado.color" dark @click="openDialogFile(item)">{{ item.estado.text }}</v-chip>
      </template>
      <template v-slot:[`item.tiporeferencia`]="{ item }">
        <span v-if="item.tiporeferencia == ''">ACTA DE REUNIÓN</span>
        <span v-if="item.tiporeferencia == 'S'">ACTA DE SEGUIMIENTO</span>
        <span v-if="item.tiporeferencia == 'E'">ACTA DE COMITÉ PERSONAL</span>
        <span v-if="item.tiporeferencia == 'C'">ACTA DE COMITÉ</span>
        <span v-if="item.tiporeferencia == 'T'">ACTA DE TAREA</span>
      </template>
      <template v-slot:[`item.tipo`]="{ item }">
        <span v-if="item.tipo == 'A'">ACUERDO</span>
        <span v-if="item.tipo == 'C'">COMPROMISO</span>
      </template>
      <template v-slot:[`item.entregable`]="{ item }">
        <v-chip
          v-if="parseInt(item.subio) === 1"
          color="primary"
          dark
          @click="exportar(item)"
          :close="item.diasrestantes >= 0"
          @click:close="eliminarEntregable(item)"
        >
          <v-icon color="white">mdi-cloud-download</v-icon>
          <span :class="['ml-3']">
            {{ item.nombreentregable }}
          </span>
        </v-chip>
        <v-chip v-else-if="item.estado.value === 'NR'" color="primary" dark>
          <v-icon color="white">mdi-cloud-upload</v-icon>
        </v-chip>
        <v-chip v-else color="primary" dark @click="openDialogFile(item)">
          <v-icon color="white">mdi-cloud-upload</v-icon>
        </v-chip>
      </template>
      <template v-slot:[`item.comentarioEntregable`]="{ item }">
        <v-edit-dialog :return-value.sync="item.comentarioEntregable" @save="changeComentarioEntregable(item)">
          {{ item.comentarioEntregable }}
          <template v-slot:input>
            <v-text-field v-model="item.comentarioEntregable" label="Comentario" single-line></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:no-data>
        <span>No se encontró información</span>
      </template>
      <template v-slot:[`item.txtresponsable`]> </template>
    </v-data-table>
    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="py-3">
          <span class="headline">ACTA: {{ actanumero }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5 py-6">
          <v-row>
            <v-col cols="12" sm="12" class="py-0">
              <v-select
                dense
                v-model="selectedStatus"
                :items="itemsStatus"
                label="Estado"
                item-text="text"
                item-value="value"
                required
              >
                <template v-slot:selection="data">
                  <v-chip :color="data.item.color" dark> {{ data.item.text }}</v-chip>
                </template>
              </v-select>
            </v-col>
            <v-col v-if="statusComplete" cols="12" sm="12" class="py-0 pt-1">
              <v-file-input
                dense
                :rules="rules"
                v-model="evidencia"
                show-size
                label="Evidencia"
                clearable
                @change="changeInputFile($event)"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeDialogUploadFile">Cerrar</v-btn>
          <v-btn color="primary" text @click="confirmDialogUploadFile">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ActasView
      v-auth-acl="'gh-act-mat_aac-view'"
      :object="objectViewActa"
      :dialog="dialogActasView"
      @closeActasView="closeActasView"
    ></ActasView>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import MatrizActaService from '@/giplan/actas/matriz_actas/services/MatrizActaService';
import moment from 'moment';
import Swal from 'sweetalert2';
import ActasView from '@/giplan/actas/matriz_actas/components/ActasView';

export default {
  name: 'MatrizActasLista',
  components: {
    ActasView,
    MatrizActasCabeceras: () => import('../components/MatrizActasCabeceras')
  },
  data: () => ({
    opcionSolo: false,
    search: '',
    menuDateRange: false,
    dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    dateRangeText: `${moment().format('DD-MM-YYYY')} ~ ${moment().format('DD-MM-YYYY')}`,
    dialogActasView: false,
    showLoading: false,
    matrizActaService: null,
    dialog: false,
    actanumero: '',
    /* status: ['PV'], */
    status: '',
    itemsStatusResponsible: [
      { value: 'RE', text: 'Realizado' },
      { value: 'PV', text: 'Por Vencer' },
      { value: 'NR', text: 'No Realizado' },
      { value: 'CA', text: 'Cancelado' }
    ],
    areas: [],
    itemsAreas: [],
    evidencia: null,
    fechaReprogramacion1: null,
    fechaReprogramacion2: null,
    fechaReprogramacion3: null,
    menuReprogramacion1: false,
    menuReprogramacion2: false,
    menuReprogramacion3: false,
    selectedStatus: 'PV',
    statusComplete: false,
    errorFile: false,
    idactaFile: '',
    itemFile: '',
    objectViewActa: {},
    rules: [
      (value) => !value || value.size < 25000000 || 'El archivo debe de tener menos de 25 MB!',
      (value) => {
        if (value === null || value === undefined) return true;
        const res = value.name.slice(value.name.lastIndexOf('.') + 1, value.name.length).toLowerCase();
        if (
          res != 'exe' &&
          res != 'sql' &&
          res != 'jar' &&
          res != 'air' &&
          res != 'src' &&
          res != 'vb' &&
          res != 'bat' &&
          res != 'dll' &&
          res != 'com' &&
          res != 'bin' &&
          res != 'apk' &&
          res != 'app' &&
          res != 'cgi' &&
          res != 'wsf' &&
          res != 'gadget' &&
          res != 'log' &&
          res != 'css' &&
          res != 'sqlite' &&
          res != 'psd' &&
          res != 'ai' &&
          res != 'bak' &&
          res != 'js' &&
          res != 'cmd'
        )
          return true;
        return 'No se permite ese tipo de archivos.';
      }
    ],
    matrizActaCabecera: {
      re: { color: 'green', title: 'REALIZADO', count: 0, percent: 0 },
      pv: { color: 'blue', title: 'POR VENCER', count: 0, percent: 0 },
      nr: { color: 'red', title: 'NO REALIZADO', count: 0, percent: 0 },
      ca: { color: 'back', title: 'CANCELADO', count: 0, percent: 0 }
    },
    itemsStatus: [
      { value: 'RE', color: 'green', text: 'Realizado' },
      { value: 'PV', color: 'blue', text: 'Por Vencer' },
      { value: 'NR', color: 'red', text: 'No Realizado' },
      { value: 'CA', color: 'back', text: 'Cancelado' }
    ],
    headers: [
      { text: 'N°', value: 'numero', width: '100', class: 'header-items-fixed nth-child1 primary', fixed: true },
      {
        text: 'COMPROMISO/ACUERDOS',
        value: 'compromisosacuerdos',
        width: '350',
        class: 'header-items-fixed nth-child2 primary',
        fixed: true
      },
      { text: 'TIPO', value: 'tipo', width: '160', class: 'primary' },
      { text: 'EMPRESA', value: 'empresa', width: '200', class: 'primary' },
      { text: 'ÁREA DEL ESCRIBANO', value: 'areaescribano', width: '320', class: 'primary' },
      { text: 'ESCRIBANO', value: 'escribano', width: '320', class: 'primary' },
      { text: 'ÁREAS INVOLUCRADAS', value: 'areainvolucrada', width: '320', class: 'primary' },
      { text: 'RESPONSABLES INVOLUCRADOS', value: 'responsableinvolucrado', width: '320', class: 'primary' },
      { text: 'ÁREA RESPONSABLE', value: 'arearesponsable', width: '320', class: 'primary' },
      { text: 'RESPONSABLE', value: 'responsable', width: '320', class: 'primary' },
      { text: '% DE PARTICIPACIÓN', value: 'participacion', width: '250', class: 'primary' },
      { text: 'ACTA N°', value: 'actanumero', width: '200', class: 'primary' },
      { text: 'TIPO ACTA', value: 'tiporeferencia', width: '200', class: 'primary' },
      { text: 'ITEM', value: 'item', width: '150', class: 'primary' },
      { text: 'FECHA DE COMPROMISO (REUNIÓN)', value: 'fechacompromiso', width: '350', class: 'primary' },
      { text: 'FECHA FIN', value: 'fechafin', width: '200', class: 'primary' },
      { text: 'FECHA DE REPROGRAMACIÓN 1', value: 'fechareprogramacion1', width: '300', class: 'primary' },
      { text: 'FECHA DE REPROGRAMACIÓN 2', value: 'fechareprogramacion2', width: '300', class: 'primary' },
      { text: 'FECHA DE REPROGRAMACIÓN 3', value: 'fechareprogramacion3', width: '300', class: 'primary' },
      { text: 'DÍAS RESTANTES', value: 'diasrestantes', width: '200', class: 'primary' },
      { text: 'ESTATUS', value: 'estado', width: '200', class: 'primary' },
      { text: '¿CUMPLIÓ?', value: 'cumplio', width: '200', class: 'primary' },
      { text: 'FECHA ENTREGABLE', value: 'fechaentregable', width: '400', class: 'primary' },
      { text: 'ENTREGABLE', value: 'entregable', width: '400', class: 'primary' },
      { text: 'COMENTARIO ENTREGABLE', value: 'comentarioEntregable', width: '300', class: 'primary' },
      /* { text: 'OBSERVACIÓN', value: 'observaciones', width: '250', class: 'primary' }, */
      { text: 'extra', value: 'txtresponsable', width: '0', class: 'd-none primary' }
    ],
    actas: []
  }),
  computed: {
    checkAllStatus() {
      return (this.status?.length ?? 0) === this.itemsStatusResponsible.length;
    },
    checkSomeStatus() {
      return (this.status?.length ?? 0) > 0 && !this.checkAllStatus;
    },
    iconCheckboxAll() {
      if (this.checkAllStatus) return 'mdi-close-box';
      if (this.checkSomeStatus) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    checkAllAreas() {
      return (this.areas?.length ?? 0) === this.itemsAreas.length;
    },
    checkSomeAreas() {
      return (this.areas?.length ?? 0) > 0 && !this.checkAllAreas;
    },
    iconCheckboxAllAreas() {
      if (this.checkAllAreas) return 'mdi-close-box';
      if (this.checkSomeAreas) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    }
  },
  watch: {
    selectedStatus(val) {
      this.evidencia = null;
      this.statusComplete = val === 'RE';
    },
    /*async status() {
      await this.initialize();
      await this.cargarAreas();
    },*/
    async opcionSolo() {
      await this.recargarListYAreas();
    },
    async dateRange(val) {
      const fecha = [];
      val.forEach((element) => {
        fecha.push(
          element
            .split('-')
            .reverse()
            .join('-')
        );
      });
      if (fecha.length === 1) {
        this.dateRangeText = `${fecha[0]} ~ `;
      }
      if (fecha.length === 2) {
        const min = Date.parse(val[0]) < Date.parse(val[1]) ? fecha[0] : fecha[1];
        const max = Date.parse(val[0]) > Date.parse(val[1]) ? fecha[0] : fecha[1];
        this.dateRangeText = `${min} ~ ${max}`;
        await this.recargarListYAreas();
      }
    }
  },
  methods: {
    responsableAcuerdo(responsable) {
      console.log(responsable);
      return responsable.filter((el) => el.isresponsable == 1);
    },
    async recargarListYAreas() {
      this.areas = [];
      await this.initialize();
      await this.cargarAreas();
    },
    async eliminarEntregable({ idacta, item }) {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: 'Desea eliminar el entregable.',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        const res = await this.matrizActaService.delete().execResource('eliminarEntregable', {
          idacta: idacta,
          item: item
        });
        if (res.status) {
          await this.alertDialog('success', res.data);
          await this.initialize();
        } else {
          await this.alertDialog('error', res.data);
        }
      }
    },
    async initialize() {
      this.showLoading = true;
      const res = await this.matrizActaService.get().execResource('matriz', {
        fecha1: Date.parse(this.dateRange[0]) < Date.parse(this.dateRange[1]) ? this.dateRange[0] : this.dateRange[1],
        fecha2: Date.parse(this.dateRange[0]) > Date.parse(this.dateRange[1]) ? this.dateRange[0] : this.dateRange[1],
        estatus: this.status,
        subarea: this.areas.toString().replaceAll('&', '%26'),
        bandera: this.opcionSolo
      });
      if (res.length > 0) {
        this.actas = [];
        res.forEach((el, index) => {
          const responsibles = el.responsables !== null ? el.responsables : [];
          this.actas.push({
            numero: index + 1,
            idacta: el.idacta,
            compromisosacuerdos: el.compromiso,
            empresa: el.empresa,
            lider: el.lider,
            areaescribano: el.areaescribano,
            escribano: el.escribano,
            tipo: el.tipo,
            tiporeferencia: el.tiporeferencia,
            arearesponsable: responsibles,
            responsable: responsibles,
            txtresponsable: responsibles.reduce((prev, curr) => `${prev},${curr.responsable}`, ''),
            txtarearesponsable: responsibles.reduce(
              (prev, curr) => `${prev},${curr.subarea !== undefined ? curr.subarea : ''}`,
              ''
            ),
            participacion: `${el.porcparticipantes}%`,
            actanumero: el.numeroacta,
            item: el.item,
            fechareprogramacion1:
              el.fechareprogramacion1 != '' ? moment(el.fechareprogramacion1).format('DD-MM-YYYY') : '',
            disabledFechareprogramacion1: el.fechareprogramacion1 !== '',
            fechareprogramacion2:
              el.fechareprogramacion2 != '' ? moment(el.fechareprogramacion2).format('DD-MM-YYYY') : '',
            disabledFechareprogramacion2: el.fechareprogramacion2 !== '',
            fechareprogramacion3:
              el.fechareprogramacion3 != '' ? moment(el.fechareprogramacion3).format('DD-MM-YYYY') : '',
            disabledFechareprogramacion3: el.fechareprogramacion3 !== '',
            fechacompromiso: moment(el.fechacompromiso).format('DD-MM-YYYY'),
            fechafin: moment(el.fechafin).format('DD-MM-YYYY'),
            diasrestantes: el.diasrestantes,
            fechaentregable: el.fechaentregable != null ? moment(el.fechaentregable).format('DD-MM-YYYY HH:mm') : '',
            estado: this.itemsStatus.filter((elem) => elem.value === el.estatus)[0], //{ value: 'PV', color: 'blue', text: 'Por Vencer' },
            cumplio: el.cumplio,
            entregable: el.entregable === '' ? null : el.entregable,
            comentarioEntregable: el.comentarioentregable,
            observaciones: el.observaciones,
            nombreentregable: el.nombreentregable,
            subio: el.subio,
            errorEntregable: { error: false, message: '' },
            class: 'class-items-fixed cell-d-none'
          });
        });
      } else {
        this.actas = [];
      }

      this.showLoading = false;

      this.calcularCabecera();
    },
    calcularCabecera() {
      this.cleanEstados();
      const total = this.actas.length;

      this.actas.forEach((el) => {
        this.switchEstados(el.estado.value, total);
      });
    },
    cleanEstados() {
      this.matrizActaCabecera.re.count = 0;
      this.matrizActaCabecera.re.percent = 0;
      this.matrizActaCabecera.pv.count = 0;
      this.matrizActaCabecera.pv.percent = 0;
      this.matrizActaCabecera.nr.count = 0;
      this.matrizActaCabecera.nr.percent = 0;
      this.matrizActaCabecera.ca.count = 0;
      this.matrizActaCabecera.ca.percent = 0;
    },
    switchEstados(estado, total) {
      switch (estado) {
        case 'RE':
          this.matrizActaCabecera.re.count += 1;
          this.matrizActaCabecera.re.percent = parseFloat(
            (parseFloat(this.matrizActaCabecera.re.count) * parseFloat(100)) / parseFloat(total)
          ).toFixed(0);
          break;
        case 'PV':
          this.matrizActaCabecera.pv.count += 1;
          this.matrizActaCabecera.pv.percent = parseFloat(
            (parseFloat(this.matrizActaCabecera.pv.count) * parseFloat(100)) / parseFloat(total)
          ).toFixed(0);
          break;
        case 'NR':
          this.matrizActaCabecera.nr.count += 1;
          this.matrizActaCabecera.nr.percent = parseFloat(
            (parseFloat(this.matrizActaCabecera.nr.count) * parseFloat(100)) / parseFloat(total)
          ).toFixed(0);
          break;
        case 'CA':
          this.matrizActaCabecera.ca.count += 1;
          this.matrizActaCabecera.ca.percent = parseFloat(
            (parseFloat(this.matrizActaCabecera.ca.count) * parseFloat(100)) / parseFloat(total)
          ).toFixed(0);
          break;
      }
    },
    async changeFechaReprogramacion1({ idacta, item, fechareprogramacion1 }) {
      this.showLoading = true;
      const res = await this.matrizActaService.put().execResource('fecharepro1', {
        idacta: idacta,
        item: item,
        fecha: fechareprogramacion1
      });
      await this.initialize();

      if (res.status) {
        await this.alertDialog('success', res.data);
      } else {
        await this.alertDialog('error', res.data);
      }
      //await this.initialize();
    },
    async changeFechaReprogramacion2({ idacta, item, fechareprogramacion2 }) {
      this.showLoading = true;
      const res = await this.matrizActaService.put().execResource('fecharepro2', {
        idacta: idacta,
        item: item,
        fecha: fechareprogramacion2
      });
      await this.initialize();

      if (res.status) {
        await this.alertDialog('success', res.data);
      } else {
        await this.alertDialog('error', res.data);
      }
      // await this.initialize();
    },
    async changeFechaReprogramacion3({ idacta, item, fechareprogramacion3 }) {
      this.showLoading = true;
      const res = await this.matrizActaService.put().execResource('fecharepro3', {
        idacta: idacta,
        item: item,
        fecha: fechareprogramacion3
      });
      await this.initialize();

      if (res.status) {
        await this.alertDialog('success', res.data);
      } else {
        await this.alertDialog('error', res.data);
      }
      //await this.initialize();
    },
    async changeComentarioEntregable({ idacta, item, comentarioEntregable }) {
      this.showLoading = true;
      const res = await this.matrizActaService.put().execResource('comentarioevidencia', {
        idacta: idacta,
        item: item,
        comentario: comentarioEntregable
      });
      this.showLoading = false;

      if (res.status) {
        await this.alertDialog('success', res.data);
      } else {
        await this.alertDialog('error', res.data);
      }
      await this.initialize();
    },

    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    closeDialogUploadFile() {
      this.actanumero = '';
      this.idactaFile = '';
      this.itemFile = '';
      this.evidencia = null;
      this.dialog = false;
    },
    async confirmDialogUploadFile() {
      const file = this.evidencia === null || this.evidencia === undefined ? null : this.evidencia;

      if (this.selectedStatus === 'RE') {
        if (file == null) {
          await this.alertDialog('warning', 'Debe de subir su evidencia para cambiar su estado a "REALIZADO"');
          return;
        }
        if (this.errorFile) {
          await this.alertDialog('warning', 'Tamaño o tipo de archivo no permitido');
          return;
        }
      }

      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Está seguro de guardar los cambios?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        const formData = new FormData();
        formData.append('idacta', this.idactaFile);
        formData.append('item', this.itemFile);
        formData.append('estatus', this.selectedStatus);
        formData.append('file', this.selectedStatus === 'RE' ? file : null);

        this.closeDialogUploadFile();

        this.showLoading = true;
        const res = await this.matrizActaService.post().execResource('subirevidencia', formData);
        await this.initialize();
        if (res.status) {
          await this.alertDialog('success', res.data);
        } else {
          await this.alertDialog('error', res.data);
        }
      }
    },
    async exportar({ idacta, item, nombreentregable }) {
      const token = localStorage.getItem('token');

      await this.matrizActaService.downloadResource(
        'descargarevidencia',
        {
          item: item,
          token: token,
          idacta: idacta
        },
        nombreentregable
      );
    },
    async exportarbase() {
      this.showLoading = true;
      const fecha1 = moment().format('YYYYMMDDHHMMSS');
      const nombreentregable = 'EXPORT-MATRIZAAC-' + fecha1 + '.xlsx';
      const token = localStorage.getItem('token');
      await this.matrizActaService.downloadResource(
        'exportarmatriz',
        {
          fecha1: Date.parse(this.dateRange[0]) < Date.parse(this.dateRange[1]) ? this.dateRange[0] : this.dateRange[1],
          fecha2: Date.parse(this.dateRange[0]) > Date.parse(this.dateRange[1]) ? this.dateRange[0] : this.dateRange[1],
          idacta: '',
          token: token,
          estatus: this.status,
          subarea: this.areas.toString().replaceAll('&', '%26'),
          bandera: this.opcionSolo
        },
        nombreentregable
      );
      this.showLoading = false;
    },
    openDialogFile({ idacta, item, actanumero, estado }) {
      this.actanumero = `${actanumero} - ${item}`;
      this.selectedStatus = estado.value;
      this.idactaFile = idacta;
      this.itemFile = item;
      this.dialog = true;
    },
    changeInputFile($event) {
      const file = $event === null || $event === undefined ? null : $event;

      if (file === null) {
        return;
      }

      this.errorFile = false;

      if (file.size > 25000000) {
        this.errorFile = true;
      }

      const res = file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase();
      if (
        res == 'exe' ||
        res == 'sql' ||
        res == 'jar' ||
        res == 'air' ||
        res == 'src' ||
        res == 'vb' ||
        res == 'bat' ||
        res == 'dll' ||
        res == 'com' ||
        res == 'bin' ||
        res == 'apk' ||
        res == 'app' ||
        res == 'cgi' ||
        res == 'wsf' ||
        res == 'gadget' ||
        res == 'log' ||
        res == 'css' ||
        res == 'sqlite' ||
        res == 'psd' ||
        res == 'ai' ||
        res == 'bak' ||
        res == 'js' ||
        res == 'cmd'
      ) {
        this.errorFile = true;
      }
    },

    updateEntregable() {
      console.log('updateEntregable', this.$refs);
    },
    itemsClass($event) {
      console.log('Rows restruct', $event);
    },
    closeActasView() {
      this.objectViewActa = {
        agendas: [],
        areaescribano: '',
        compromisos: [],
        escribano: '',
        invitados: [],
        idarea: '',
        idresponsable: '',
        idtrabajador: '',
        lider: '',
        nombresall: ''
      };
      this.dialogActasView = false;
    },
    async viewActas({ idacta }) {
      this.showLoading = true;
      this.objectViewActa = {
        agendas: [],
        areaescribano: '',
        compromisos: [],
        escribano: '',
        invitados: [],
        tiporeferencia: '',
        nombresall: ''
      };

      const res = await this.matrizActaService.get().execResource('viewacta', {
        idacta: idacta
      });
      this.showLoading = false;
      this.objectViewActa = {
        titulo: res.titulo,
        subtitulo: res.subtitulo,
        agendas: res.agendas,
        areaescribano: res.areaescribano,
        compromisos: res.compromisos,
        escribano: res.escribano,
        invitados: res.invitados,
        tiporeferencia: res.tiporeferencia,
        codigo: idacta,
        numeroacta: res.numeroacta
      };
      this.dialogActasView = true;
    },
    async selectAllStatus() {
      this.$nextTick(() => {
        if (this.checkAllStatus) {
          this.status = [];
        } else {
          this.status = this.itemsStatusResponsible.map((element) => {
            return element.value;
          });
        }
      });
    },
    selectAllAreas() {
      this.$nextTick(() => {
        if (this.checkAllAreas) {
          this.areas = [];
        } else {
          this.areas = this.itemsAreas.map((element) => {
            return element.value;
          });
        }
      });
    },
    async cargarAreas() {
      this.itemsAreas = [];
      this.actas.forEach((el) => {
        el.responsable.forEach((el2) => {
          let exist = -1;
          exist = this.itemsAreas.findIndex((el3) => el3.value == el2.idsubarea);
          if (exist == -1 && el2.idsubarea != '') {
            this.itemsAreas.push({ value: el2.idsubarea, text: el2.subarea });
          }
        });
      });
      this.itemsAreas.sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
      this.areas = this.itemsAreas.map((el) => el.value);
    }
  },
  filters: {
    convertSizeFiles(size) {
      const bytes = parseInt(size);
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '';
      const i = Math.floor(Math.log(bytes) / Math.log(1024));
      if (i === 0) return `${bytes} ${sizes[i]}`;
      const res = (bytes / Math.pow(1024, i)).toFixed(2);
      return `(${res} ${sizes[i]})`;
    }
  },
  async mounted() {},
  async created() {
    this.matrizActaService = this.$httpService(new MatrizActaService(), this);
    this.showLoading = true;
    await this.selectAllStatus();
    await this.initialize();
    await this.cargarAreas();
    this.showLoading = false;
  }
};
</script>
<style lang="scss">
.alturaacta > .v-data-table__wrapper {
  height: calc(100vh - 390px);
}
.header-items-fixed {
  position: sticky !important;
  position: -webkit-sticky !important;
}

.header-items-fixed.nth-child1 {
  top: 0;
  left: 0;
  z-index: 4 !important;
}
.header-items-fixed.nth-child2 {
  top: 0;
  left: 100px;
  border-right: thin solid rgba(250, 250, 250) !important;
  z-index: 4 !important;
}
.class-items-fixed td:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 3;
}
.class-items-fixed td:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 100px;
  z-index: 3;
}
.cell-d-none td:last-child {
  display: none;
}

.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  z-index: 3;
  color: white !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed {
  background: white;
  color: rgba(0, 0, 0, 0.6);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.7);
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed td:nth-child(1) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed td:nth-child(1) {
  background: #1e1e1e;
  color: white;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed td:nth-child(2) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed td:nth-child(2) {
  background: #1e1e1e;
  color: white;
  border-right: thin solid rgba(255, 255, 255, 0.12);
}

.text-field-search-matriz.theme--light.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87);
}
.text-field-search-matriz.theme--dark.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--dark.v-label {
  color: rgba(0, 0, 0, 0.6);
}
</style>
